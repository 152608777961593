@import url('https://api.fontshare.com/css?f[]=gambetta@2,2&display=swap');



 .header {
    /* padding: 20px; */
    background-color: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #30302B;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    z-index: 1;

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 20px;
    }
  }
  
  @keyframes moveOnRefresh { 
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .titreh1 {
    margin-left: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-family: 'Gambetta', serif;
    letter-spacing: -3px;
    transition: 700ms ease;
    font-variation-settings: "wght" 311;
    margin-bottom: 0.8rem;
    color: PaleGoldenRod;
    outline: none;
    text-align: center;
    animation: moveOnRefresh 1s ease-in-out;

    @media (max-width: 600px) {
      margin: 0;
      font-size: 2rem;
    }
  }

  .titreh1:hover {
    font-variation-settings: "wght" 582; 
    letter-spacing: 1px;
    outline: none;
  }

  .buttons {
  display: flex;

  @media (max-width: 600px) {
    padding: 20px;
    margin-bottom: 10px;
  }
  }

  .borderRight {
    border-right-style: none !important;
  }

  .button {
    background-color: transparent;
    color: PaleGoldenRod;
    font-family: 'Gambetta', serif;
    border: solid 1px #30302B;
    border-top-style: none;
    padding: 39px 48px;
    transition: border-bottom-color 0.3s;
    border-bottom-color: transparent;
    position: relative;
    overflow: hidden;
    z-index: 2;

    @media (max-width: 600px) {
      padding: 20px;
      margin-bottom: 10px;
      border-left-style: none !important;
    }
  }

  .button::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: transparent;
    content: "";
    transition: all 0.5s;
    z-index: 3;
  }
  
  .button:hover::after {
    height: 100%;
    background-color: PaleGoldenRod;
    transition: height 1s, background-color 1s;
  }

  .button:hover {
    border-bottom-color: transparent;
    color: black;
    mix-blend-mode: difference;
  }
  
  .button span {
    position: relative;
    z-index: 4;
  }
  /*FAIRE EN SORTE QUE LE HEADER SE REDUISE QUAND UN SCROLL VERS LE BAS COMME SUR FONTSHARE*/